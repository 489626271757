import React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/global.css'
import favicon from '../../static/favicon.ico'

export default function Layout({ children }) {
  return (
    <div className='layout'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Infopanel</title>
        <link rel='icon' type='image/png' href={favicon} />
      </Helmet>
      <div className='content'>
        {children}
      </div>
    </div>
  )
}
